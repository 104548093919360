<template>
  <div>
    <v-card class="mb-2">
      <v-card-title>Financial Journal</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="dateRange"></date-range-picker>

        <bank-select v-model="bankId" class="mt-2" select-first></bank-select>
      </v-card-text>
    </v-card>

    <balance-card
      :amount="total != 0 ? currencyFormat(total) : 0"
      class="mb-2"
      title="Saldo Akhir"
    ></balance-card>

    <v-dialog>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mb-2"
          color="#c25b0c"
          block
          x-large
          >Input Transaksi</v-btn
        >
      </template>

      <v-card>
        <v-card-title class="bg-orange py-4">Pilih tipe input</v-card-title>

        <v-divider></v-divider>

        <v-list nav>
          <v-list-item
            v-for="(inputType, index) in inputTypes"
            :key="index"
            :to="`${inputType.to}?bankId=${bankId}`"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ inputType.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>{{ inputType.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-card class="mb-2">
      <v-text-field
        v-model="search"
        class="mb-4"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="fetchData"
      ></v-text-field>

      <transaction-types-autocomplete v-model="transactionType" class="mb-2"></transaction-types-autocomplete>

      <v-data-table
        :headers="headers"
        :item-class="itemClass"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
        disable-sort
      >
        <template #[`item.destination`]="{ item }">
          <span v-if="item.person_type_id == 0">Lain - lain</span>

          <span v-if="item.person_type_id == 2">
            Customer - {{ item.person_name }}
          </span>

          <span v-if="item.person_type_id == 3">
            Supplier - {{ item.person_name }}
          </span>
        </template>

        <template #[`item.memo`]="{ item }">
          <div class="py-3">
            {{ item.memo }}
          </div>
        </template>

        <template #[`item.amount`]="{ item }">
          {{ currencyFormat(item.amount) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn
            color="light-blue"
            @click="openDetails(item.type, item.trans_no)"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import TransactionTypesAutocomplete from '@/components/Autocompletes/TransactionTypesAutocomplete.vue'
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'
import BankSelect from '@/components/Selects/BankSelect.vue'
import BalanceCard from '@/components/Cards/BalanceCard.vue'
import TYPES from '../../../../constants/types'

export default {
  components: { TransactionTypesAutocomplete, DateRangePicker, BankSelect, BalanceCard },

  data() {
    return {
      dateRange: null,
      bankId: null,
      search: '',
      transactionType: null,
      options: {},
      headers: [
        { text: 'Referensi', value: 'ref' },
        { text: 'Tanggal', value: 'trans_date' },
        { text: 'Nilai', value: 'amount' },
        { text: 'Memo', value: 'memo' },
        { text: '', sortable: false, value: 'actions' },
      ],
      items: [],
      count: 0,
      total: 0,
      loading: false,
      inputTypes: [
        {
          icon: 'mdi-login-variant',
          title: 'Penerimaan',
          to: '/accounting/deposits/create',
        },
        {
          icon: 'mdi-logout-variant',
          title: 'Pengeluaran',
          to: '/accounting/expenses/create',
        },
        {
          icon: 'mdi-transfer',
          title: 'Transfer',
          to: '/accounting/bank-transfers/create',
        },
      ],
    }
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchData()
      },
    },

    dateRange() {
      this.fetchData()
    },

    bankId() {
      this.fetchData()
      this.getBankBalance()
    },

    transactionType() {
      this.fetchData()
    }
  },

  methods: {
    currencyFormat(value) {
      return Intl.NumberFormat('id-ID', {
        currency: 'IDR',
      }).format(value)
    },

    fetchData() {
      if (!this.dateRange || !this.bankId) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.items = []
      this.loading = true

      console.log(this.transactionType)

      this.$http
        .get('/accounting/banks/journals', {
          params: {
            bankId: this.bankId,
            search: this.search,
            orderBy: sortBy[0] ? sortBy[0] : '',
            orderDesc: sortDesc[0] ? 1 : 0,
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage > 0 ? itemsPerPage : '',
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
            type: this.transactionType !== null ? this.transactionType : null
          },
        })
        .then((result) => {
          this.items = result.data.result
          this.count = result.data.count
          this.loading = false
        })
    },

    openDetails(type, transNo) {
      let link = `/accounting/banks/journals/${type}/${transNo}`

      if (type == TYPES.TT_BANKPAYMENT) link = `/accounting/expenses/${transNo}`
      else if (type == TYPES.TT_BANKDEPOSIT)
        link = `/accounting/deposits/${transNo}`
      else if (type == TYPES.TT_BANKTRANSFER)
        link = `/accounting/bank-transfers/${transNo}`

      this.$router.push(link)
    },

    async getBankBalance() {
      if (this.bankId) {
        await this.$http
          .get('/banks/' + this.bankId + '/balance')
          .then((response) => {
            this.total = response.data
          })
      } else {
        this.total = null
      }
    },

    itemClass(item) {
      return item.amount < 0 ? 'bg-red' : 'bg-green'
    },
  },
}
</script>

<style>
thead.v-data-table-header-mobile {
  display: none !important;
}
</style>

<style lang="scss" scoped>
::v-deep .bg-red td:nth-child(3) {
  background-color: #241313;
}

::v-deep .bg-green td:nth-child(3) {
  background-color: #132417;
}

::v-deep .bg-orange {
  background-color: #c25b0c;
}
</style>
