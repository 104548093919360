const types = {
  ST_JOURNAL: 0,
  ST_BANKPAYMENT: 1,
  ST_BANKDEPOSIT: 2,
  ST_BANKTRANSFER: 4,
  ST_SALESINVOICE: 10,
  ST_CUSTCREDIT: 11,
  ST_CUSTPAYMENT: 12,
  ST_CUSTDELIVERY: 13,
  ST_CUSTRECEIVABLE: 14,
  ST_LOCTRANSFER: 16,
  ST_INVADJUST: 17,
  ST_PURCHORDER: 18,
  ST_SUPPINVOICE: 20,
  ST_SUPPCREDIT: 21,
  ST_SUPPAYMENT: 22,
  ST_SUPPRECEIVE: 25,
  ST_MANUPROPOSAL: 45,
  ST_WORKORDER: 26,
  ST_MANUISSUE: 28,
  ST_MANURECEIVE: 29,
  ST_SALESORDER: 30,
  ST_SALESQUOTE: 32,
  ST_COSTUPDATE: 35,
  ST_DIMENSION: 40
}
  
export default types