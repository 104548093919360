<template>
  <v-card color="#131a24">
    <v-card-text class="white--text">
      <h4  class="grey--text text--lighten-1 pb-1">{{ title }}</h4>
      <h1>{{ amount }}</h1>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    amount: {
      type: [String, Number],
      default: 0,
    },
  },
}
</script>
