<template>
  <v-select
    v-model="model"
    :items="banks"
    label="Kas/Bank"
    item-text="bank_account_name"
    item-value="id"
    hide-details="auto"
    outlined
    @change="$emit('input', model)"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    selectFirst: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    model: null,
    endpoint: '/banks',
    banks: [],
    loading: false,
  }),

  async mounted() {
    this.loading = true

    await this.fetch()

    if (this.selectFirst) {
      this.model = this.banks[0].id
      this.$emit('input', this.model)
    }

    this.loading = false
  },

  methods: {
    async fetch() {
      await this.$http.get(this.endpoint).then((response) => {
        this.banks = response.data
      })
    },
  },
}
</script>
