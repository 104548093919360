import TRANSACTION_TYPES from './transaction-types'

const names = {}

names[TRANSACTION_TYPES.ST_JOURNAL] = 'Input Jurnal'
names[TRANSACTION_TYPES.ST_BANKPAYMENT] = 'Pembayaran Bank'
names[TRANSACTION_TYPES.ST_BANKDEPOSIT] = 'Penerimaan Bank'
names[TRANSACTION_TYPES.ST_BANKTRANSFER] = 'Transfer Dana'
names[TRANSACTION_TYPES.ST_CUSTPAYMENT] = 'Pembayaran Customer'
names[TRANSACTION_TYPES.ST_SUPPAYMENT] = 'Pembayaran ke Supplier'

export default names