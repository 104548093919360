<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :value="value"
    hide-details="auto"
    item-text="name"
    item-value="id"
    label="Tipe Transaksi"
    auto-select-first
    outlined
    @change="$emit('input', model)"
  ></v-autocomplete>
</template>

<script>
import TRANSACTION_TYPE_NAMES from '@/constants/transaction-type-names'

export default {
  props: {
    value: {
      type: [String, Number],
      value: null,
    },
  },

  data: () => ({
    model: null,
    types: Object.entries(TRANSACTION_TYPE_NAMES),
  }),

  computed: {
    items() {
      let items = [{ id: null, name: 'Semua' }]

      items = items.concat(this.types.map((type) => {
        return {
          id: type[0],
          name: type[1],
        }
      }))

      return items
    },
  },
}
</script>
